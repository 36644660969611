<template>
  <div class="content">
    <div class="box">
      <img src="@/assets/images/limit.png" alt="" />
      <p>本平台仅支持在微信中使用，</p>
      <p>请前往微信打开此页面进行访问</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  .nav {
    width: 100%;
    height: 45px;
    line-height: 45px;
    border-bottom: 0;
    .van-nav-bar__title {
      color: #262626;
      font-size: 18px;
    }
  }
  .box {
    width: 100%;
    height: calc(100% - 45px);
    overflow: hidden;
    img {
      display: block;
      width: 98px;
      margin: 232px auto 0;
    }
  }
  p {
    margin-top: 12px;
    font-size: 15px;
    color: #afafaf;
    text-align: center;
  }
  p:nth-child(3) {
    margin-top: 0;
  }
}
</style>
